import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SearchInput } from '../shared/form-control/SearchInput';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '../shared/form-control/Input';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientContactService from '../../services/ClientContactService';
import { ClientContactFilter, ClientContactResponse } from '../../models/ClientContacts';
import FilterTag, { FilterSelectMode } from '../shared/tags/FilterTag';
import { Option } from '../Option';
import ClientTagService from '../../services/ClientTagService';
import { BulkTagRequestOperation, ClientTagResponse, ClientTagType } from '../../models/ClientTag';
import LanguageUtils from '../../utils/LanguageUtils';
import useDebounce from '../../hooks/useDebounce';
import { SelectItemContextConsumer, SelectItemContextProvider, SelectItemsContextType } from '../../contexts/select-items/SelectItemsContext';
import SkeletonLoader from '../shared/skeleton-loader/SkeletonLoader';
import ContactRow from '../contacts/ContactRow';
import addressBookImg from '../../assets/images/address-book.svg';
import AddContactModal from '../contacts/AddContactModal';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import Loader from '../shared/Loader';
import DateUtils from '../../utils/DateUtils';
import TagIcon from '../shared/icon/TagIcon';
import TrashIcon from '../shared/icon/TrashIcon';
import Tooltip from '../shared/Tooltip';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { Trans } from 'react-i18next';
import { SelectListMenu } from '../shared/SelectListMenu';
import CheckIcon from '../shared/icon/CheckIcon';
import { useQueryClient } from '@tanstack/react-query';

const OrgContacts: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('organisation');

  const currentClient = useRecoilValue(currentClientAtom);

  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [contacts, setContacts] = useState<ClientContactResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [searchPhrase, setSearchPhrase] = useState('');
  const debouncedSearchPhrase = useDebounce(searchPhrase, 500);

  const [tags, setTags] = useState<ClientTagResponse[]>([]);
  const [tagFilters, setTagFilters] = useState<Option<string, boolean>[]>([]);
  const [jobTitleFilters, setJobTitleFilters] = useState<Option<string, boolean>[]>([]);
  const [companyFilters, setCompanyFilters] = useState<Option<string, boolean>[]>([]);

  const [newModalOpen, setNewModalOpen] = useState(false);
  const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
  const [bulkTagOpen, setBulkTagOpen] = useState(false);

  const [newTagModalOpen, setNewTagModalOpen] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [bulkTagOptionChanges, setBulkTagOptionChanges] = useState<Option<string, boolean>[]>([]);
  const queryClient = useQueryClient();

  const filters = useMemo<Partial<ClientContactFilter>>(
    () => ({
      jobTitles: jobTitleFilters.filter((x) => x.value).map((x) => x.text),
      companies: companyFilters.filter((x) => x.value).map((x) => x.text),
      tags: tagFilters.filter((x) => x.value).map((x) => x.id),
    }),
    [companyFilters, jobTitleFilters, tagFilters],
  );

  const refreshList = useCallback(() => {
    setCurrentPage(1);
    setTotalPages(0);
    setContacts([]);
    setInitialLoading(true);
  }, []);

  useEffect(() => {
    refreshList();
  }, [debouncedSearchPhrase, refreshList]);

  const fetchList = useCallback(() => {
    setLoadingPage(true);

    ClientContactService.getAllPaged(currentClient!.id, { pageNumber: currentPage, searchTerm: debouncedSearchPhrase, ...filters }).then((res) => {
      setContacts((prev) => {
        if (currentPage === 1) {
          return res.data;
        }
        return [...prev, ...res.data];
      });
      setCurrentPage(res.pageNumber || 1);
      setTotalPages(res.totalPages || 0);
      setTotalCount(res.totalCount || 0);
      setInitialLoading(false);
      setLoadingPage(false);
    });
  }, [currentClient, currentPage, debouncedSearchPhrase, filters]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => setCurrentPage((prev) => prev + 1) : null, loadingPage);

  useEffect(() => {
    fetchList();
    // totalPages included so it refreshes when the list gets re-populated because of added item
  }, [currentPage, totalPages, fetchList]);

  useEffect(() => {
    ClientTagService.getAllTags(currentClient!.id, { types: [ClientTagType.UserGroup], pageSize: 9999 }).then((res) => {
      setTags(res.data);
      setTagFilters((prev) =>
        res.data.map((x) => ({
          id: x.id,
          text: LanguageUtils.getTranslation('name', x.translations, language),
          value: prev.find((p) => p.id == x.id)?.value ?? false,
        })),
      );
    });
    // totalPages included so it refreshes when the list gets re-populated because of added item
  }, [currentClient, language, totalPages]);

  useEffect(() => {
    ClientContactService.getFilterValues(currentClient!.id, 'jobTitle').then((res) => {
      setJobTitleFilters((prev) => res.data.map((x) => ({ id: x, text: x, value: prev.find((p) => p.id == x)?.value ?? false })));
    });

    ClientContactService.getFilterValues(currentClient!.id, 'company').then((res) => {
      setCompanyFilters((prev) => res.data.map((x) => ({ id: x, text: x, value: prev.find((p) => p.id == x)?.value ?? false })));
    });
    // totalPages included so it refreshes when the list gets re-populated because of added item
  }, [currentClient, totalPages]);

  const linkTag = useCallback(
    (tagId: string, contactId: string) => {
      ClientTagService.linkTag(currentClient!.id, tagId, contactId).then(() => {
        setContacts((prev) =>
          prev.map((x) => {
            if (x.id === contactId) {
              return { ...x, tags: [...x.tags, tags.find((x) => x.id === tagId)!] };
            }
            return x;
          }),
        );
        queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
      });
    },
    [currentClient, queryClient, tags],
  );

  const unlinkTag = useCallback(
    (tagId: string, contactId: string) => {
      ClientTagService.unlinkTag(currentClient!.id, tagId, contactId).then(() => {
        setContacts((prev) =>
          prev.map((x) => {
            if (x.id === contactId) {
              return { ...x, tags: x.tags.filter((t) => t.id !== tagId) };
            }
            return x;
          }),
        );
        queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
      });
    },
    [currentClient, queryClient],
  );

  const deleteContact = useCallback(
    (contactId: string) => {
      ClientContactService.deleteContact(currentClient!.id, contactId).then(() => {
        setContacts((prev) =>
          prev.map((x) => {
            if (x.id === contactId) {
              return { ...x, deletedUtc: DateUtils.addDays(30).toISOString() };
            }

            return x;
          }),
        );
        queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
      });
    },
    [currentClient, queryClient],
  );

  const restoreContact = useCallback(
    (contactId: string) => {
      ClientContactService.restoreContact(currentClient!.id, contactId).then(() => {
        setContacts((prev) =>
          prev.map((x) => {
            if (x.id === contactId) {
              return { ...x, deletedUtc: undefined };
            }

            return x;
          }),
        );
        queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
      });
    },
    [currentClient, queryClient],
  );

  const deleteContactsBulk = useCallback(
    (context: SelectItemsContextType) => {
      ClientContactService.bulkDelete(currentClient!.id, {
        selectedAll: context.selection.selectedAll,
        excludedIds: context.selection.excludedIds,
        selectedIds: Object.entries(context.selection.selectionStatus)
          .filter(([_, value]) => value)
          .map(([key, _]) => key),
        itemFilters: { ...filters, searchTerm: debouncedSearchPhrase },
      }).then(() => {
        refreshList();
        queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
      });
    },
    [currentClient, debouncedSearchPhrase, filters, queryClient, refreshList],
  );

  const selectText = useCallback(
    (selection: SelectItemsContextType['selection']) => {
      if (selection.selectedAll || selection.selectedCount === totalCount) {
        return 'contacts.selection.all';
      }

      if (selection.selectedCount === contacts.length) {
        return 'contacts.selection.all-visible';
      }

      return 'contacts.selection.some';
    },
    [contacts.length, totalCount],
  );

  const selectionBarText = useCallback(
    (context: SelectItemsContextType) => (
      <Trans
        t={t}
        i18nKey={selectText(context.selection)}
        tOptions={{
          Count: context.selection.selectedCount,
          Total: (totalCount ?? 0) - context.selection.excludedIds.length,
        }}
        components={{
          Link: (
            <Button
              className="!p-0"
              type={ButtonType.TERTIARY}
              onClick={() =>
                context.selection.selectedAll || context.selection.selectedCount === totalCount
                  ? context.mutateSelection.clear(contacts)
                  : context.mutateSelection.selectAll(true)
              }
            />
          ),
        }}
      />
    ),
    [contacts, selectText, t, totalCount],
  );

  const saveBulkTagChanges = useCallback(
    (selection: SelectItemsContextType['selection']) => {
      Promise.all(
        bulkTagOptionChanges.map((x) => {
          return ClientTagService.bulkTag(currentClient!.id, x.id, {
            excludedIds: selection.excludedIds,
            operation: x.value ? BulkTagRequestOperation.Add : BulkTagRequestOperation.Remove,
            selectedAll: selection.selectedAll,
            selectedIds: Object.entries(selection.selectionStatus)
              .filter(([_, value]) => value)
              .map(([key, _]) => key),
            itemFilters: { ...filters, searchTerm: debouncedSearchPhrase },
          });
        }),
      ).then(() => {
        setBulkTagOptionChanges([]);
        refreshList();
        queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
      });
    },
    [bulkTagOptionChanges, currentClient, debouncedSearchPhrase, filters, queryClient, refreshList],
  );

  const multiTagFooter = useCallback(
    (selection: SelectItemsContextType['selection']) => {
      return function MultiTagFooter() {
        return (
          <div className="px-2 py-1">
            {bulkTagOptionChanges.length > 0 ? (
              <Button
                type={ButtonType.SECONDARY}
                size={ButtonSize.S}
                onClick={() => {
                  setBulkTagOpen(false);
                  saveBulkTagChanges(selection);
                }}
              >
                {t('contacts.group-actions.save-changes')}
              </Button>
            ) : (
              <Button
                type={ButtonType.SECONDARY}
                size={ButtonSize.S}
                onClick={() => {
                  setBulkTagOpen(false);
                  setNewTagModalOpen(true);
                }}
              >
                {t('contacts.group-actions.create-tag')}
              </Button>
            )}
          </div>
        );
      };
    },
    [bulkTagOptionChanges.length, saveBulkTagChanges, t],
  );

  const addBulkTagChange = useCallback((option: Option<string, boolean>) => {
    option.value = !option.value;
    setBulkTagOptionChanges((prev) => {
      const existing = prev.find((x) => x.id === option.id);
      if (existing) {
        return prev.map((x) => (x.id === option.id ? option : x));
      }
      return [...prev, option];
    });
  }, []);

  const createNewTag = useCallback(
    (selection: SelectItemsContextType['selection']) => {
      ClientTagService.createTag(currentClient!.id, { type: ClientTagType.UserGroup, translations: { [language]: { name: newTag } } })
        .then((res) => {
          setTags((prev) =>
            [...prev, res.data].sort((a, b) =>
              LanguageUtils.getTranslation('name', a.translations, language).localeCompare(
                LanguageUtils.getTranslation('name', b.translations, language),
              ),
            ),
          );
          setTagFilters((prev) => [...prev, { id: res.data.id, text: newTag, value: false }].sort((a, b) => a.text.localeCompare(b.text)));

          return res;
        })
        .then((res) => {
          return ClientTagService.bulkTag(currentClient!.id, res.data.id, {
            excludedIds: selection.excludedIds,
            operation: BulkTagRequestOperation.Add,
            selectedAll: selection.selectedAll,
            selectedIds: Object.entries(selection.selectionStatus)
              .filter(([_, value]) => value)
              .map(([key, _]) => key),
            itemFilters: { ...filters, searchTerm: debouncedSearchPhrase },
          });
        })
        .then(() => {
          refreshList();
          setNewTag('');
          setNewTagModalOpen(false);
          queryClient.invalidateQueries({ queryKey: ['clientTags', currentClient!.id] });
        });
    },
    [currentClient, debouncedSearchPhrase, filters, language, newTag, queryClient, refreshList],
  );

  return (
    <div className="flex h-full flex-col pt-6">
      <div className="flex justify-between">
        <Heading size={HeadingSize.H3}>{t('contacts.title')}</Heading>
        <div className="flex justify-between gap-4">
          <div className="w-80">
            <SearchInput
              style={InputStyle.MINIMAL}
              placeholder={t('contacts.search-placeholder')}
              value={searchPhrase}
              onChange={(e) => {
                setSearchPhrase(e.target.value);
              }}
              data-cy="search-contacts"
            />
          </div>
          <Button size={ButtonSize.S} onClick={() => setNewModalOpen(true)}>
            {t('contacts.add-contact')}
          </Button>
        </div>
      </div>

      <div className="mb-4">
        <div className="flex gap-2">
          <FilterTag
            tag={{
              id: 'tags',
              text: t('contacts.filters.tags'),
              value: 'tags',
            }}
            onFiltersChange={setTagFilters}
            mode={FilterSelectMode.Multi}
            options={tagFilters}
          />
          <FilterTag
            tag={{
              id: 'jobTitle',
              text: t('contacts.filters.jobTitle'),
              value: 'jobTitle',
            }}
            onFiltersChange={setJobTitleFilters}
            mode={FilterSelectMode.Multi}
            options={jobTitleFilters}
          />

          <FilterTag
            tag={{
              id: 'company',
              text: t('contacts.filters.company'),
              value: 'company',
            }}
            onFiltersChange={setCompanyFilters}
            mode={FilterSelectMode.Multi}
            options={companyFilters}
          />
        </div>
      </div>

      <SkeletonLoader ready={!initialLoading} type="listBlockRow" rows={7} disableCentered>
        {contacts.length > 0 && (
          <div className="bg-gray-5 rounded-[10px] px-2 py-3">
            <SelectItemContextProvider>
              <div className="flex items-center gap-4">
                <SelectItemContextConsumer>
                  {(context) => (
                    <>
                      <div className="border-l-2 border-transparent pl-4">
                        <div className="w-10">{context.SelectAllButton({ visibleItems: contacts.filter((x) => !x.deletedUtc) })}</div>
                      </div>

                      {context.selection.active ? (
                        <div className="flex flex-grow gap-2">
                          <SelectListMenu
                            noMatchMessage={MultiTagNoTags}
                            options={tags.map((x) => ({
                              id: x.id,
                              text: LanguageUtils.getTranslation('name', x.translations, language),
                              value:
                                bulkTagOptionChanges.find((p) => p.id == x.id)?.value ??
                                !!contacts.filter((c) => context.selection.isSelected(c)).find((c) => c.tags.find((t) => t.id === x.id)),
                            }))}
                            isOpen={bulkTagOpen}
                            blurOnClick={false}
                            onBlur={() => {
                              setBulkTagOpen(false);
                              setBulkTagOptionChanges([]);
                            }}
                            footer={multiTagFooter(context.selection)}
                            customListItemRenderer={BulkTagListRenderer}
                            onClick={addBulkTagChange}
                            width="w-fit"
                            placement="bottom-start"
                          >
                            {(triggerProps) => (
                              <span {...triggerProps}>
                                <Tooltip text={t('contacts.group-actions.tag')}>
                                  {(tooltip) => (
                                    <span {...tooltip}>
                                      <TagIcon className="text-primary-1 h-6 w-6" onClick={() => setBulkTagOpen(true)} />
                                    </span>
                                  )}
                                </Tooltip>
                              </span>
                            )}
                          </SelectListMenu>
                          <Tooltip text={t('contacts.group-actions.delete')}>
                            {(tooltip) => (
                              <span {...tooltip}>
                                <TrashIcon className="text-primary-1 h-6 w-6" onClick={() => setBulkDeleteModalOpen(true)} />
                              </span>
                            )}
                          </Tooltip>

                          <div className="border-gray-2 border-l pl-2">{selectionBarText(context)}</div>
                        </div>
                      ) : (
                        <>
                          <div className="flex w-full">
                            <div className="flex w-full gap-4">
                              <div className="flex-grow">{t('contacts.headings.name')}</div>
                              <div className="w-56">{t('contacts.headings.phone-number')}</div>
                              <div className="w-56">{t('contacts.headings.company-position')}</div>
                              <div className="w-56">{t('contacts.headings.tags')}</div>
                              <div className="w-24">{t('contacts.headings.status')}</div>
                            </div>
                            <div className="w-8">{/* spacer */}</div>
                          </div>
                          <div className="w-10">{/* spacer */}</div>
                        </>
                      )}

                      <ModalContext.Provider
                        value={{ open: bulkDeleteModalOpen, modalWidth: 'w-[500px]', onClose: () => setBulkDeleteModalOpen(false) }}
                      >
                        <StandardModal
                          title={t('contacts.delete-contact-modal.title-multi')}
                          confirmButtonTitle={t('contacts.delete-contact-modal.buttons.cancel')}
                          cancelButtonTitle={t('contacts.delete-contact-modal.buttons.confirm')}
                          onConfirmClick={() => setBulkDeleteModalOpen(false)}
                          onCancelClick={() => {
                            setBulkDeleteModalOpen(false);
                            deleteContactsBulk(context);
                          }}
                        >
                          {t('contacts.delete-contact-modal.description-multi')}
                        </StandardModal>
                      </ModalContext.Provider>

                      <ModalContext.Provider
                        value={{
                          open: newTagModalOpen,
                          modalWidth: 'w-2/5',
                          onClose: () => {
                            setNewTag('');
                            setNewTagModalOpen(false);
                          },
                        }}
                      >
                        <StandardModal
                          title={t('contacts.create-tag-modal.title')}
                          confirmDisabled={!newTag}
                          confirmButtonTitle={t('contacts.create-tag-modal.buttons.create')}
                          onCancelClick={() => {
                            setNewTag('');
                            setNewTagModalOpen(false);
                            setBulkTagOpen(true);
                          }}
                          onConfirmClick={() => createNewTag(context.selection)}
                        >
                          <Input
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            label={t('contacts.create-tag-modal.label')}
                            placeholder={t('contacts.create-tag-modal.label-placeholder')}
                          />
                        </StandardModal>
                      </ModalContext.Provider>
                    </>
                  )}
                </SelectItemContextConsumer>
              </div>

              {contacts.map((contact) => (
                <ContactRow
                  key={contact.id}
                  contact={contact}
                  tags={tagFilters}
                  addTag={(tagId) => linkTag(tagId, contact.id)}
                  removeTag={(tagId) => unlinkTag(tagId, contact.id)}
                  deleteContact={() => deleteContact(contact.id)}
                  resoreContact={() => restoreContact(contact.id)}
                />
              ))}
              <div ref={lastElementRef} />
              {loadingPage && (
                <div className="relative h-12">
                  <Loader size={12} />
                </div>
              )}
            </SelectItemContextProvider>
          </div>
        )}

        {contacts.length === 0 && (
          <div className="rounded-[10px] bg-white py-12 text-center">
            <div className="flex justify-center">
              <img src={addressBookImg} alt="" />
            </div>
            <Heading size={HeadingSize.H5} className="mb-2 mt-4 font-medium">
              {t('contacts.none-found.title')}
            </Heading>
            <div>{t('contacts.none-found.description')}</div>
          </div>
        )}
      </SkeletonLoader>

      <AddContactModal open={newModalOpen} onClose={() => setNewModalOpen(false)} contactCreated={refreshList} />
    </div>
  );
};

export default OrgContacts;

const MultiTagNoTags: FC = () => {
  const { t } = useTranslation('organisation');
  return (
    <div className="not-italic">
      <div className="font-medium">{t('contacts.group-actions.tag-none.title')}</div>
      <div className="text-dpm-14">{t('contacts.group-actions.tag-none.description')}</div>
    </div>
  );
};

const BulkTagListRenderer: FC<Option<string, boolean>> = (tag) => {
  return (
    <div className="flex cursor-pointer items-center">
      <div className="-ml-2 inline-block pr-2">
        <TagIcon className="h-6 w-6" />
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <span>{tag.text}</span>
        <CheckIcon className={`${tag.value ? '' : 'invisible'} text-semantic-1 h-6 w-6 flex-shrink-0`} />
      </div>
    </div>
  );
};
