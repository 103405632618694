import { FC, useMemo, useState } from 'react';
import DataRow from '../shared/data-grid/DataRow';
import Checkbox from '../shared/form-control/Checkbox';
import ProfileInitials from '../shared/profile-image/ProfileInitials';
import { ClientContactResponse } from '../../models/ClientContacts';
import { useItemSelection } from '../../contexts/select-items/SelectItemsContext';
import Tooltip from '../shared/Tooltip';
import { useTranslation } from 'react-i18next';
import { Option } from '../Option';
import { ContextMenuItem } from '../shared/ContextMenu';
import EditIcon from '../shared/icon/EditIcon';
import TrashIcon from '../shared/icon/TrashIcon';
import TagIcon from '../shared/icon/TagIcon';
import CheckIcon from '../shared/icon/CheckIcon';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import DateUtils from '../../utils/DateUtils';
import { useNavigate } from 'react-router';
import TagGroup from '../tags/TagGroup';

type Props = {
  contact: ClientContactResponse;
  tags: Option<string, unknown>[];
  addTag: (tagId: string) => void;
  removeTag: (tagId: string) => void;
  deleteContact: () => void;
  resoreContact: () => void;
};

const ContactRow: FC<Props> = (props) => {
  const { contact, tags, addTag, removeTag, deleteContact, resoreContact } = props;
  const { selection, mutateSelection } = useItemSelection();
  const { t } = useTranslation('organisation');
  const navigate = useNavigate();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const status = useMemo(() => {
    const deletedDate = new Date(contact.deletedUtc || '');
    if (!contact.deletedUtc || deletedDate.getTime() < new Date().getTime()) {
      return <span className="text-semantic-light-1 font-medium">{t('contacts.status.active')}</span>;
    }

    return (
      <Tooltip text={t('contacts.status.deleted-tooltip', { DateTime: DateUtils.formatDateTime(deletedDate) })}>
        {(tooltip) => <span {...tooltip}>{t('contacts.status.deleted')}</span>}
      </Tooltip>
    );
  }, [contact.deletedUtc, t]);

  const contextItems = useMemo(() => {
    const items: ContextMenuItem[] = [
      {
        title: t('contacts.context-menu.edit'),
        icon: <EditIcon className="h-6 w-6" />,
        hide: !!contact.deletedUtc,
        onClick() {
          navigate(`/clients/${contact.clientId}/contacts/${contact.id}`);
        },
      },
      {
        title: t('contacts.context-menu.delete'),
        icon: <TrashIcon className="h-6, w-6" />,
        hide: !!contact.deletedUtc,
        onClick() {
          setDeleteModalOpen(true);
        },
      },
      {
        title: t('contacts.context-menu.restore'),
        icon: <EditIcon className="h-6, w-6" />,
        hide: !contact.deletedUtc,
        onClick() {
          resoreContact();
        },
      },
    ];

    if (contact.deletedUtc) {
      return items;
    }

    return items.concat([
      {
        title: t('contacts.context-menu.tags-heading'),
        hasDivider: true,
      },
      ...tags.map((tag) => {
        const hasTag = !!contact.tags.find((x) => x.id === tag.id);

        return {
          onClick() {
            hasTag ? removeTag(tag.id) : addTag(tag.id);
          },
          customRenderer() {
            return (
              <div className="flex items-center">
                <div className="-ml-2 inline-block pr-2">
                  <TagIcon className="h-6 w-6" />
                </div>
                <div className="flex w-full items-center justify-between gap-2">
                  <span>{tag.text}</span>
                  <CheckIcon className={`${hasTag ? '' : 'invisible'} text-semantic-1 h-6 w-6 flex-shrink-0`} />
                </div>
              </div>
            );
          },
        };
      }),
    ]);
  }, [t, contact.deletedUtc, contact.clientId, contact.id, contact.tags, tags, navigate, resoreContact, removeTag, addTag]);

  return (
    <DataRow contextMenuItems={contextItems} onClick={() => navigate(`/clients/${contact.clientId}/contacts/${contact.id}`)}>
      <div className={`flex w-full items-center gap-4 ${contact.deletedUtc ? 'text-gray-2' : 'text-black'}`}>
        <div className={`w-10 ${contact.deletedUtc ? 'invisible' : ''}`}>
          <Checkbox value={selection.isSelected(contact)} onChange={(value) => mutateSelection.selectIndividual(contact, value)} />
        </div>

        <div className="flex flex-grow items-center gap-3">
          <ProfileInitials firstName={contact.firstName} lastName={contact.lastName} />
          <div>
            <div>
              {contact.firstName} {contact.lastName}
            </div>
            <div className="text-gray-2">{contact.email}</div>
          </div>
        </div>

        <div className="w-56 truncate">{contact.properties.phoneNumber}</div>

        <div className="w-56 truncate">
          <Tooltip
            text={`${contact.properties.company}${contact.properties.company && contact.properties.jobTitle ? ', ' : ''}${contact.properties.jobTitle}`}
            truncatedTextMode
          >
            {(tooltip) => (
              <div {...tooltip} className="truncate">
                {contact.properties.company}
                {contact.properties.company && contact.properties.jobTitle ? ', ' : ''}
                {contact.properties.jobTitle}
              </div>
            )}
          </Tooltip>
        </div>

        <div className="flex w-56 items-center gap-2">
          <TagGroup tags={contact.tags} />
        </div>

        <div className="w-24">{status}</div>
      </div>

      <ModalContext.Provider value={{ open: deleteModalOpen, modalWidth: 'w-[500px]', onClose: () => setDeleteModalOpen(false) }}>
        <StandardModal
          title={t('contacts.delete-contact-modal.title')}
          confirmButtonTitle={t('contacts.delete-contact-modal.buttons.cancel')}
          cancelButtonTitle={t('contacts.delete-contact-modal.buttons.confirm')}
          onConfirmClick={() => setDeleteModalOpen(false)}
          onCancelClick={() => {
            setDeleteModalOpen(false);
            deleteContact();
          }}
        >
          {t('contacts.delete-contact-modal.description')}
        </StandardModal>
      </ModalContext.Provider>
    </DataRow>
  );
};

export default ContactRow;
